/* # GRID

Element width is calculated as x/12 of page flex-basis
You don't need to use full width (12 columns) - in that case row is by default aligned to left (legacy rule) - use .row.centered to center it


```
<div class="row">
<div class="span6"><p>Span 6</p></div>
<div class="span3"><p>Span 3</p></div>
<div class="span3"><p>Span 3</p></div>
</div>
	```

*/

@import '../variables/variables.less';


/* ## gutter for the grid */

// .AVsizer is defined to make code transfer from Avast easier
#median-modal,
#downloadPopup {
    .container,
    .AVsizer {
        padding-left: 20px;
        padding-right: 20px;
    }

    .row {
        display: flex;
    }

    /* ## Desktop and tablet resolution  */
    @media @tablet, @desktop {

        .row {
            margin-left: -20px;
            margin-right: -20px;
            flex-direction: row;
            justify-content: center;
            align-items: stretch;

            [class*="span"] {
                min-height: 1px;
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        /* flex-basis definitions */
        .span12 {
            flex-basis: 100%;
        }
        .span11 {
            flex-basis: 91.66666665%;
        }
        .span10 {
            flex-basis: 83.33333332%;
        }
        .span9 {
            flex-basis: 75%;
        }
        .span8 {
            flex-basis: 66.66666665%;
        }
        .span7 {
            flex-basis: 58.33333332%;
        }
        .span6 {
            flex-basis: 50%;
        }
        .span5 {
            flex-basis: 41.66666665%;
        }
        .span4 {
            flex-basis: 33.33333332%;
        }
        .span3 {
            flex-basis: 25%;
        }
        .span2 {
            flex-basis: 16.66666667%;
        }
        .span1 {
        flex-basis: 8.33333332%;
        }
    }


    /* ## Tablet and mobile resolution */

    @media @tablet, @mobile {

    // fixing size of the images inside grid
    [class*="span"]  img {
        max-width: 100%;
    }
    }

    /* ## Mobile resolution */
    @media @mobile {
    .row {
        flex-direction: column;

            [class*="span"]  {
                min-height: 1px;
                flex-basis: auto;
            }
        }
    }


    /* ## Parameters */

    .row {
        // switching left and right column (desktop, tablet)
        &.reverse,
        &.swap {
        @media @tablet, @desktop {
            flex-direction: row-reverse;
        }
    }
    // horizontal alignment (desktop, tablet)
    &.left  {
        @media @tablet, @desktop {
            justify-content: flex-start;
        }
    }
    &.right  {
        @media @tablet, @desktop {
            justify-content: flex-end;
        }
    }
    // columns without padding (desktop, tablet, mobile)
    &.nopadding [class*="span"] {
        @media @tablet, @desktop {
            padding: 0px;
        }
        @media @mobile {
            padding: 0 0 20px;
        }
    }

    // column parameters

    // columns containing images has no padding on mobile
    [class*="span"] {
        &.img {
            @media @mobile {
                padding: 0;
            }
        }
    }

    }
}
